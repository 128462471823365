/**
 * The formatters-custom file is a place to define custom formatters or to
 * override any of the baked-in formatters like phone number formatting.
 *
 * e.g. You may write something like below
 * export function address(profile) {
 *   return `111 Yext Street`;
 * }
 */

export function getMonthDay(dateValue) {
  // Split the input date value (assuming it's in DD/MM/YYYY format)
  const [day, month, year] = dateValue.split('/');

  // Rearrange to YYYY-MM-DD format for correct interpretation
  const formattedDate = [year, month, day].join('-');

  // Create the Date object
  const date = new Date(formattedDate);

  // Extract month and day in French locale
  const monthName = date.toLocaleString('fr-FR', { month: 'long' });
  const dayNumber = date.getDate();
  const yearNumber = date.getFullYear();

  // Return the formatted object
  return {
    month: monthName,
    day: dayNumber,
    year: yearNumber
  };
}
